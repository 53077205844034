import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { clock, dummy2, threeDots, user } from '../../assets'
import { authReq } from '../../requests'
import './appointmentView.css'
import { bookAppointmentGAPI } from '../../pages/admin/appointments/Appointments'
const AppointmentView = (data) => {
    const updateStatus = async () => {
        await authReq(
            "PATCH",
            `/appointment/${props._id}`,
            { status: "patient-canceled" }
        )
        data.setRefresh(!refresh)
    }

    const props = data.appointment
    props.free = props.slot?.includes(':45')
    const { onCancel, onAccept, onReschedule, onCall } = data
    const [modelShow, setModelShow] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [currentDate, setCurrentDate] = useState(Date.now())

    useEffect(() => {
        setInterval(() => {
            setCurrentDate(Date.now())
        }, 1000)
    }, [])

    const navigate = useNavigate()
    return (
        <div className='kwn-appointment_view'>
            <div className='kwn-side_divider' />
            <div className='kwn-appointment_content_top_view'>
                <div className='kwn-appointment_title_view'>
                    <h2>Appointment date</h2>
                </div>
                <div className='kwn-appointment_time_view'>
                    <img src={[console.log(props), clock][1]} />
                    <h2 style={props?.rescheduleRequestedBy ? { textDecoration: 'line-through' } : {}}>{props.type === 'patient-completed' || props.type === 'patient-canceled' ? `${new Date(props.appointmentStart).toLocaleString()}` : `${new Date(props.appointmentStart).toLocaleString()}`}</h2>
                </div>
                {props?.rescheduleRequestedBy && <div className='kwn-appointment_time_view'>
                    <img src={[console.log(props), clock][1]} />
                    <h2>{new Date(props.rescheduleAppointmentStart).toLocaleString()}</h2>
                </div>}
                <div className='kwn-appointment_divider' />
                <div className='kwn-appointment_profile_view'>
                    <img src={props.appointer?.image} />
                    <div className='kwn-appointment_name_view'>
                        <h3>{props.appointer?.name}</h3>
                        <h4>{props.appointer?.preferences?.speciality ?? ''}</h4>
                        {props.rescheduleRequestedBy == props?.appointee?._id  && <h4 style={{ fontSize: '11px' }}>Requested Rescheduling to {new Date(props.rescheduleAppointmentStart).toLocaleString()}</h4>}
                    </div>
                </div>
                <div style={{ margin: '2px' }}></div>
                {console.log("PROP TYPE =?", props.rescheduleRequestedBy, props?.appointer?.name, "_:>", props.rescheduleRequestedBy == props?.appointee?._id)}
                {props.type === 'patient-pending' || props.rescheduleRequestedBy == props?.appointer?._id ? <div className='kwn-appointment_buttons_view'>
                    <div className='kwn-appointment_button' style={{
                        width: '31%',
                        cursor: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? "default" : "pointer",
                        opacity: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? 0.5 : 1
                    }} onClick={() => !((props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate) && onCancel(props)}>
                        <h3>CANCEL</h3>
                    </div>
                    <div className='kwn-appointment_button' style={{
                            width: '31%',
                            color: 'black',
                            backgroundColor: 'rgb(248, 184, 78)',
                            cursor: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? "default" : "pointer",
                            opacity: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? 0.5 : 1
                        }} onClick={async () => {
                        if((props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate) return
                        onAccept(props)
                        console.log("MY-APPOINTMENT", props)
                        await bookAppointmentGAPI(props)
                    }}>
                        <h3 style={{ color: 'black' }}>ACCEPT</h3>
                    </div>
                    <div className='kwn-appointment_button' style={{
                        width: '31%', 
                        order: "0.7px solid #006039",
                        backgroundColor: '#fff'
                    }} onClick={() => onReschedule(props, props.free, props.token)}>
                        <h3 style={{ color: '#006039' }}>RESCHEDULE</h3>
                    </div>
                </div> : (props.type == 'patient-canceled' || props.type == "patient-completed" || props.rescheduleRequestedBy == props?.appointee?._id ? <></> : <div className='kwn-appointment_buttons_view'>
                        <div className='kwn-appointment_button' style={{ border: "0.7px solid #006039", backgroundColor: '#fff', color: '#006039' }} onClick={() => onReschedule(props._id, props.free, props.token)}>
                            <h3 style={{ color: '#006039' }}>RESCHEDULE</h3>
                        </div>
                        {console.log("ltx", props)}
                        <div className='kwn-appointment_button' style={{
                            backgroundColor: '#006039',
                            opacity: props.appointmentStart - 5*60*1000 > currentDate || props.appointmentEnd + 5*60*1000 < currentDate ? 0.5 : 1
                        }} onClick={() => onCall(props, props.free, props.token)}>
                            <h3>JOIN</h3>
                        </div>
                </div>)}
            </div>
        </div>
    )
}

export default AppointmentView
