import React, { useState } from "react";
import { apple, facebookBlue, google } from "../../../assets";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { req } from "../../../requests";
import { getAllParams } from "../../../urlParams";
import "./signIn.css";

export default function SignIn() {
  const { signedUp } = getAllParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const socialArray = [
    {
      id: 1,
      title: "Continue with Google",
      icon: google,
    },
    {
      id: 1,
      title: "Continue with Facebook",
      icon: facebookBlue,
    },
    {
      id: 1,
      title: "Continue with Apple",
      icon: apple,
    },
  ];


  localStorage.clear()
  // window.location.href = "https://www.kavanhealth.com/log-in"
  // return <></>

  return (
    <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-sign_in-form_container">
          <div
            style={{ alignItems: "flex-start" }}
            className="kwn-fill_out_form-title_container"
          >
            <h1>
              {signedUp
                ? "Espere mientras revisamos su solicitud"
                : "Bienvenido"}
            </h1>
            <h2 style={{ textAlign: "left" }}>
              Inicie sesión para empezar a utilizar Kavan Health
            </h2>
          </div>
          <TextInput
            type={"text"}
            title={"Email"}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            placeholder={"Johndoe@mail.com"}
          />
          <TextInput
            type={"password"}
            title={"Password"}
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            placeholder={"************"}
          />

          <div className="kwn-sign_in-forgot_container">
            <p className="kwn-sign_in-new_to_kavan">
              Crear una nueva cuenta?
              <span onClick={() => navigate("/signUp")}>Registrarse</span>
            </p>
            <p
              onClick={() => navigate("/forgotpassword")}
              className="kwn-sign_in-forgot_password"
            >
              ¿Has olvidado tu contraseña?
            </p>
          </div>
        </div>

        <div
          style={{
            color: "red",
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 20,
          }}
        >
          {error}
        </div>
        <div className="kwn-sign_in-Button_container">
          <Button
            onClick={async () => {
              let success = true;
              setLoading(true);
              const data = await req(
                "POST",
                "/user/testLogin",
                {
                  email: email.toLowerCase().trim(),
                  password,
                  device: {
                    id: "web",
                    deviceToken: "MockToken",
                  },
                },
                async (message) => {
                  setLoading(false);
                  if(message?.includes?.('verificación')) {
                    req(
                      'POST', 
                      "/user/sendOTP", 
                      {
                        email,
                        device: {
                          id: "web",
                          deviceToken: "MockToken",
                        },
                      }
                    ).then(_ => console.log("sendotp", _))
                    navigate(`/verifyEmail?email=${email}`)
                  }
                  success = false;
                  setError(message);
                }
              );
              if (!success) return;
              setLoading(false);
              console.log(data);
              console.log("Tokens =>", data);
              // return navigate(`/verifyEmail?email=${email}`)
              const { user, token, refreshToken, accountLink } = data;
              localStorage.setItem("kawan_accessToken", token);
              localStorage.setItem("kawan_refreshToken", refreshToken);

              if(!user?.doctorType) return navigate('/choosePofile')
              else if(!user?.gender) return navigate(user?.doctorType == 'coach' ? '/coach' : '/psychologist')
              else if(!user?.startDateUTC) return navigate('/availability')
              else {
                if (!user?.approved)
                  return setError(
                    "Please wait, we'll soon accept your request to be registered at Kavan Health"
                  );
                if (accountLink) return (window.location.href = accountLink.url);  
              }


              navigate("/dashboard/AdminHome");
            }}
          >
            {loading ? "Loading..." : "Iniciar sesión"}
          </Button>
        </div>
        <h3>OR</h3>
        <div>
          {/* {socialArray.map((item) => { */}
          {/* return ( */}
          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              const jwt = credentialResponse.credential;
              const decoded = jwt_decode(jwt);
              const email = decoded?.email;
              const name = decoded?.name;
              const result = { email, name };

              let success = true;
              setLoading(true);
              const data = await req(
                "POST",
                "/user/socialLogin",
                {
                  email,
                  name,
                  device: {
                    id: "web",
                    deviceToken: "MockToken",
                  },
                },
                (message) => {
                  setLoading(false);
                  success = false;
                  setError(message);
                }
              );
              if (!success) return;
              setLoading(false);
              console.log(data);
              console.log("Tokens =>", data);
              const {
                user,
                userAlreadyExisted,
                token,
                refreshToken,
                accountLink,
              } = data;
              localStorage.setItem("kawan_accessToken", token);
              localStorage.setItem("kawan_refreshToken", refreshToken);
              if (!userAlreadyExisted) return navigate("/choosePofile");
              if (!user.approved) return navigate("/registrationPending");
              if (accountLink) return (window.location.href = accountLink.url);
              navigate("/dashboard/AdminHome");

              console.log(result);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
          {/* ); */}
          {/* })} */}
        </div>
      </div>
      <Footer />
    </GoogleOAuthProvider>
  );
}
