import React, { useState, useEffect } from "react";
import { bell, dummy, dummy1, dummy2, dummy3, menuBurger } from "../../assets";
import "./topMenu.css";
import { authReq } from "../../requests";
import { useNavigate } from "react-router-dom";

const TopMenu = ({ togglefun }) => {
  const [toggle, setToggle] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [appointmentArray, setAppointmentArray] = useState([]);
  const [chatsArray, setChatArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    authReq("GET", "/user/me").then((data) => {
      const user = data?.data;

      //   console.log(user, "user");
      setLoading(false);
      setUser(user);
    });
  }, [refresh]);

  useEffect(() => {
    authReq("GET", "/appointment?currentDate="+Date.now()).then((data) => {
      console.log(data);
      setAppointmentArray(
        data?.appointments
          ?.filter(
            (x) =>
              new Date(x.appointmentStart).toISOString().split("T")[0] ===
              new Date().toISOString().split("T")[0]
          )
          .map((app) => {
            return {
              id: app._id,
              title: app.appointer.name,
              disease: app?.appointer?.preferences?.speciality,
              image: app.appointer.image,
            };
          }) ?? []
      );
      console.log(data.messages);
      setChatArray(
        data.messages.map((x, id) => {
          return { id, image: x.sender.image };
        })
      );
    });
  }, []);
  const toggleFun = () => {
    setToggle(!toggle);
  };
  return (
    <div
      style={{ position: "fixed", zIndex: 100 }}
      className="kwn-layout-top_menu-main_container"
    >
      <div className="kwn-layout-menu-burger-container" onClick={togglefun}>
        <img src={menuBurger} />
      </div>

      <div className="kwn-layout-profile-image-container">
        <img src={user?.image} />
      </div>

      <div className="kwn-layout-today-appointment-container">
        <h2>Today Appointment</h2>
        <p onClick={() => navigate("/dashboard/AppointmentStack")}> See all</p>
      </div>
      <div className="kwn-layout-today-recent_chat-container">
        <h2>Recent Chat</h2>
        <div className="kwn-layout-recent_chat-profile-container">
          {chatsArray.map((item) => (
            <img
              onClick={() => navigate("/dashboard/ChatStack")}
              src={item?.image}
            />
          ))}
        </div>
      </div>

      <div className="kwn-layout-notification-bell-container">
        <img onClick={toggleFun} src={bell} />
        {toggle ? (
          <div className={"kwn-layout-notification-tooltip-container"}>
            <div className="kwn-layout-notification-tooltip-text-container">
              <p>You were found for appointment.</p>
              <p>You were found for appointment.</p>
              <p>You were found for appointment.</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TopMenu;
