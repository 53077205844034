import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  arrowLeft,
  arrowRight,
  clock,
  dummy1,
  dummy2,
  search,
  sideBarAppointment,
  threeDots,
  users,
} from "../../../assets";
import { AppointmentView, SearchBar, Button, Loader } from "../../../components";
import { WithAuth } from "../../../components/auth/auth.js";
import { authReq } from "../../../requests.js";
import { Area } from "@ant-design/plots";
import "./dashboard.css";
import cache from "../../../cache";
import { bookAppointmentGAPI } from "../appointments/Appointments";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { fireDB } from "../../../firebase.js";

const loginSet = (kawan_accessToken, kawan_refreshToken) => {
  document.cookie = `kawan_accessToken=${kawan_accessToken};path=/;domain=.kavanhealth.com`
}

const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [graphArray, setGraphArray] = useState([]);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const navigate = useNavigate()
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth())
  const [user, setUser] = useState({})
  const [appointments, setAppointments] = useState([])
  const [newAppointments, setNewAppointments] = useState(0)
  const [viewMore, setViewMore] = useState(false)
  const [hasMoreAppointments, setHasMoreAppointments] = useState(true)
  const [appointmentPatientArray, setAppointmentPatientArray] = useState([]);
  const [appointmentShow, setAppointmentShow] = useState([])
  const [refresh, setRefresh] = useState(false)

  const config = {
    xField: "Date",
    yField: "scales",
    xAxis: {
      range: [0, 1],
      tickCount: 7,
    },
    line: { color: "#006039", size: 4 },
    color: "#006039",

    areaStyle: () => {
      return {
        fillOpacity: 10,
        fill: "l(270) 0:#ffffff 0.3:#a4d2cd 0.5:#59a392 0.7:#499783 1:#006039",
      };
    },
  };

  const updateGraph = (appointments, month, year) => {
    setGraphArray(new Array(29).fill(0).map((_, i) => i+1).map(i => {
      return {
        Date: `${i}`,
        scales: appointments?.filter(app => {
      console.log("||||||||||||||||||||||",  new Date(app.appointmentStart).getMonth(), month, new Date(app.appointmentStart).getFullYear(), year)
          return new Date(app.appointmentStart).getDay() == i && new Date(app.appointmentStart).getFullYear() == year && new Date(app.appointmentStart).getMonth() == month
        })?.length,
      }
    }))
  }

  useEffect(() => {
    if(!user?._id) return;
    setLoading(true)
    const q = query(collection(fireDB, 'appointments'), where('appointee._id', '==', user?._id))
    // authReq('GET', "/appointment?page=" + page)
    const f = snapshot => {
      setLoading(false)
      const appointments = snapshot.docs.map(doc => doc.data())
      if(!appointments?.length) return setHasMoreAppointments(true)
      console.log("appointments?.length", appointments?.length, appointments)
      setAppointmentShow([
        ...appointmentShow,
        ...appointments,
      ])
    }
    // getDocs(q).then(f)
    onSnapshot(q, f)
  }, [user])

  useEffect(() => {

    authReq('GET', "/appointment?noDoctorAppointments=true&noMessages=true&currentDate=" + Date.now())
      .then(data => {
        console.log("NOW_DATA", data)

        const dataSet = new Set()
        setAppointmentPatientArray([
          {id: 1, title: "CItas", count: data.appointments?.filter(app => app.status == "patient-upcoming" || app.status == "patient-complete").length, img: sideBarAppointment},
          {id: 2, title: "Pacientes", count: data.appointments?.filter(app => {
            if(dataSet.has(`${app.appointer?._id}`)) return false
            dataSet.add(`${app.appointer?._id}`)
            return true
          })?.length, img: users},
        ])
  
        const appointments = data.appointments.sort((a, b) => a > b ? -1 : 1)
        const newestAppointmentId = localStorage.getItem('kawan_newestPatientId', appointments[0]?._id) ?? '0'
        console.log("Appointments", newestAppointmentId, appointments)
        const newAppointments = appointments
          ?.filter(x => x.status == "patient-pending")
          ?.length
        setNewAppointments(newAppointments)
        
        if(appointments[appointments.length - 1]) localStorage.setItem('kawan_newestPatientId', appointments[appointments.length - 1]?._id)
        console.log("ABCDEFGH", appointments[0], newAppointments)
        setAppointments(appointments)

        updateGraph(appointments, month, year)
        setLoading(false)
      })

  }, [refresh])

  useEffect(() => {
    const f = async () => {
      if(user) {
        const appointments_ = await cache.get(user._id)
        if(!appointments_?.length) return
        if(appointmentPatientArray?.length) return

        const data = { appointments: appointments_  }
        if(typeof data.appointments == "string") data.appointments = JSON.parse(data.appointments)
        const dataSet = new Set()
        console.log("NOW_DATA_222", data)
        setAppointmentPatientArray([
          {id: 1, title: "CItas", count: data.appointments.filter(app => app.status == "patient-upcoming" || app.status == "patient-complete").length, img: sideBarAppointment},
          {id: 2, title: "Pacientes", count: data.appointments.filter(app => {
            if(dataSet.has(`${app.appointer._id}`)) return false
            dataSet.add(`${app.appointer._id}`)
            return true
          }).length, img: users},
        ])
  
        const appointments = data.appointments.sort((a, b) => a > b ? -1 : 1)
        const newestAppointmentId = localStorage.getItem('kawan_newestPatientId', appointments[0]?._id) ?? '0'
        console.log("Appointments", newestAppointmentId, appointments)
        const newAppointments = appointments
          ?.filter(x => x.status == "patient-pending")
          ?.length
        setNewAppointments(newAppointments)
        
        if(appointments[appointments.length - 1]) localStorage.setItem('kawan_newestPatientId', appointments[appointments.length - 1]?._id)
        console.log("ABCDEFGH", appointments[0], newAppointments)
        setAppointments(appointments)
  
        updateGraph(appointments, month, year)
        setAppointments(appointments)
        setLoading(false)
        await cache.set(user._id, appointments)
      }
    }

    f()
  }, [user])

  useEffect(() => {
    authReq('GET', "/user/me", null, () => {
      loginSet("")
      localStorage.clear()
      window.location.href = "https://kavanhealth.com/log-in"
    })
    .then(data => setUser(data?.data))
  }, [])

  return <div id="dashboard-container" className="kavan_admin_main_container">
  {loading && <Loader/>}
  <div className="kwn-search">
    <SearchBar />
  </div>
  <div className="kwn-dashboard-title_container">
    <h1>Kavan Dashboard</h1>
    <h3>Welcome, {user?.name}!</h3>
  </div>
  <div className="kwn-dashboard-resume_container">
    <h1>Solicitud De Citas </h1>
  </div>
  <div className="kwn-dashboard-solicitud-container">
    <div className="kwn-dashboard-solicitud-sub-container">
      <div className="kwn-dashboard-side_line"></div>
      <div className="kwn-dashboard-solicitud-tag">
        <p>{newAppointments ? `Tienes ${newAppointments} nuevas solicitudes` : "No tienes solicitudes nuevas"}</p>
      </div>
    </div>

    <div className="kwn-dashboard-solicitud-btn" onClick={() => navigate("/dashboard/AppointmentStack")}>
      <p>Ver solicitudes</p>
    </div>
  </div>
  <div className="kwn-dashboard-resume_container">
    <h1>Resumen</h1>
  </div>
  <div className="kwn-dashboard-appointments_and_patients_top_view">
    {appointmentPatientArray.map((item) => {
      return (
        <div className="kwn-dashboard-appointments_and_patients_view">
          <div className="kwn-dashboard-round_calender_view">
            <img src={item.img} />
          </div>
          <div>
            <h2>{item.count}</h2>
            <h3>{item.title}</h3>
          </div>
        </div>
      );
    })}
  </div>

  <div className="kwn-dashboard-arrows_top_view">
    <h1 className="kwn-heading">Patient’s Graph</h1>
    <div className="kwn-dashboard-arrows_view">
      <div>
        <img src={arrowLeft} style={{cursor: 'pointer'}} onClick={() => {
          let newMonth = month - 1
          let newYear = year
          if(newMonth < 0) {
            newMonth = 11
            newYear = year - 1
          }
          setYear(newYear)
          setMonth(newMonth)
          updateGraph(appointments, newMonth, newYear)
        }} />
      </div>
      <div>
        <img src={arrowRight} style={{cursor: 'pointer'}} onClick={() => {
          let newMonth = month + 1
          let newYear = year
          if(newMonth > 11) {
            newMonth = 0
            newYear = year + 1
          }
          setMonth(newMonth)
          setYear(newYear)
          updateGraph(appointments, newMonth, newYear)
        }} />
      </div>
    </div>
  </div>

  <div className="kwn-dashboard-total_patients_view">
    <h3>Total appointment in {monthNames[month]}</h3>
    <h4>{appointments?.filter(app => new Date(app.appointmentStart).getFullYear() == year && new Date(app.appointmentStart).getMonth() == month).length}</h4>
    {/* <h5>20.5 %</h5> */}
  </div>
  <div className="kwn-dashboard-graph_view">
    <Area data={graphArray} {...config} />
  </div>

  <div className="kwn-dashboard-appointments_top_view">
    <h1 className="kwn-heading">appointments requests </h1>
    <div className="kwn-dashboard-appointment_view">
      {[...appointmentShow]?.slice?.(viewMore ? 0 : 4)?.sort((a, b) => a.appointmentStart > b.appointmentStart ? -1 : 1)?.map(appointment => <AppointmentView
        user={user}
        onCancel={async ({ _id: id, rescheduleRequestedBy, ...props }) => {
          const obj = {
            status: "patient-canceled",
            ...(rescheduleRequestedBy ? {
              rescheduleRequestedBy: null,
              rescheduleAppointmentStart: null,
              rescheduleAppointmentEnd: null,
            } : {})
          }
          setAppointments(appointments.map(x => x._id == id ? obj : x))
          setAppointmentShow(
            appointments.map(x => 
              x._id == id ? {...props, ...obj} : x
            )
          )
          await authReq(
            "PATCH",
            `/appointment/${id}`,
            obj
          )
        }}
        onCall={(params, free, token) => window.location.href = `https://staging.kavanhealth.com/videoCall?sessionName=${params._id}&userName=${user?.name}`}
        onReschedule={async ({_id: id}, free) => {
          navigate(
            `/dashboard/AppointmentStack/rescheduleAppointment?id=${
              id
            }${free ? "&free=1" : ""}`
          );
        }}
        onAccept={async ({ _id: id, rescheduleRequestedBy, rescheduleAppointmentStart, rescheduleAppointmentEnd, ...props }) => {
          const obj = {
            status: "patient-upcoming",
            ...(rescheduleRequestedBy ? {
              rescheduleRequestedBy: null,
              appointmentStart: rescheduleAppointmentStart,
              appointmentEnd: rescheduleAppointmentEnd,
            } : {})
          }
          setAppointments(
            appointments.map(x => 
              x._id == id ? {...props, ...obj} : x
            )
          )
          setAppointmentShow(
            appointments.map(x => 
              x._id == id ? {...props, ...obj} : x
            )
          )
          await authReq(
            "PATCH",
            `/appointment/${id}`,
            obj
          )
          // await bookAppointmentGAPI(appointments.find(x => x._id == id))
        }}
        appointment={{...appointment, setRefresh, type: appointment.status}} />)}
    </div>
    {!viewMore && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
      <Button onClick={() => {
        setViewMore(true)
      }}>View More</Button>
    </div>}
  </div>
</div>}

export default Dashboard;
