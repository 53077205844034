import dayjs from "dayjs";
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clock, dummy2, threeDots } from "../../../assets";
import { AppointmentView, SearchBar, Loader } from "../../../components";
import { authReq } from "../../../requests";
import Button from "@mui/material/Button";

import "./appointments.css";
import SmallBtn from "../../../components/smallBtn/smallBtn";
import { getAllParams } from "../../../urlParams";
import { gapi } from "gapi-script"
import ApiCalendar from 'react-google-calendar-api';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { baseURL } from "../../../constants";
import cache from "../../../cache";
import socketIO from "socket.io-client";
import { fireDB } from "../../../firebase";

const googleImage = "https://static.vecteezy.com/system/resources/previews/012/871/371/original/google-search-icon-google-product-illustration-free-png.png"


const config = {
  "clientId": "475320902582-ahnmc5e4ssn96pn1ueevpec8so2s8tvp.apps.googleusercontent.com",
  "apiKey": "AIzaSyBK51BITyljFaXsBrhJd0_mg0uFjat_yZc",
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const timeZone = "UTC"

const apiCalendar = new ApiCalendar(config)

var appointmentStatusChanging = false
const setAppointmentStatusChanging = bool => {
  if (bool) appointmentStatusChanging = bool
  else setTimeout(() => {
    appointmentStatusChanging = bool
  }, 5000);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const isPhone = () => getWindowDimensions().width < 421

class GoogleCalender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: null,
      status: "Sync",
    }
    this.handleItemClick = this.handleItemClick.bind(this)
    this.genDiv = this.genDiv.bind(this)

    this.interval = setInterval(async () => {
      const authStatus = gapi?.client?.getToken() != this.state.auth
      if (gapi && authStatus) {
        if (authStatus) {
          this.setState({ auth: gapi?.client?.getToken(), status: "Syncing" })
          try {
            await this.props.signIn()
            this.setState({ auth: gapi?.client?.getToken(), status: "In Sync" })
          } catch (_) {
            alert("Usage Limits Exceed")
            this.setState({ auth: gapi?.client?.getToken(), status: "Error" })
          }
        }
        else {
          this.setState({ auth: gapi?.client?.getToken(), status: "Signing Out" })
          this.props.signOut()
          this.setState({ auth: gapi?.client?.getToken(), status: "Sync" })
        }
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleItemClick(event, name) {
    try {
      if (name === 'sign-in') {
        apiCalendar.handleAuthClick()
      } else if (name === 'sign-out') {
        apiCalendar.handleSignoutClick()
      }
    } catch (e) {
      console.log(e)
    }
  }

  genDiv(name) {
    return <div
      onClick={(e) => this.handleItemClick(e, name)}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        border: "solid 1px lightgrey",
        borderRadius: "10px",
        padding: "10px",
        width: "160px",
        cursor: this.state.status === "Sync" ? "pointer" : "auto",
        marginBottom: "20px",
      }}
    >
      <img src={googleImage} style={{ width: '50px', height: 'auto' }} />
      <div style={{
        fontSize: "25px",
        margin: "10px",
        fontFamily: "Inter",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
        color: this.state.status === "Error" ? "red" : "black",
      }}>
        <div>{this.state.status}</div>
      </div>
    </div>
  }
  render() {
    return <>
      {gapi?.client?.getToken() === null ? this.genDiv("sign-in") : this.genDiv("sign-out")}
    </>
  }
}

function DatePicker(props) {
  const [isOpen, setIsOpen] = useState(props.open);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleCalendar = () => {
    setIsOpen(!isOpen); // Toggle the calendar's open/close state
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    toggleCalendar(); // Close the calendar after selecting a date
  };

  return (
    <Datetime
      locale="de"
      input={false}
      onBlur={props.onClick} // Toggle the calendar on text blur
      closeOnSelect
      value={props.selectedDate}
      onChange={props.onChange}
    />
  );
}

const addDays = function (date, days) {
  var date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
}

const addHours = function (date, hours) {
  var date = new Date(date);
  date.setHours(date.getHours() + hours);
  return date;
}

const formatDateYYMMDD = startDateTime => `${startDateTime.getFullYear()}-${startDateTime.getMonth() + 1}-${startDateTime.getDate()}/${startDateTime.getHours()}`

const let_ = (vars, f) => f(...vars)

const getAppointmentByDay = (date, hour, appointmentsGroupedByDay) => appointmentsGroupedByDay[`${formatDateYYMMDD(date).split("/")[0]}/${hour.split(':')[0]}`]
const dateByHours = (x) => {
  const d = new Date()
  d.setHours(x)
  return d
}
const getHours = d => parseInt(d.toString().split(" ")[4].split(":")[0])
const range = (start, end) => {
  const arr = []
  let i = 0
  for (let startDate = dateByHours(start); getHours(startDate) <= end; startDate = addHours(startDate, 1)) {
    if (i != 0 && startDate.getHours() == 0) break
    arr.push(startDate.getHours())
    if (arr.length > 25) break
    i++
  }
  return [...new Set(arr)]
}

function daysOfWeek(today) {
  let daysUntilNextMonday = (7 - today.getDay() + 1) % 7; // 0 = Sunday, 1 = Monday, ...
  let daysSinceLastMonday = (today.getDay() + 6) % 7;

  if (daysUntilNextMonday == 0 && daysSinceLastMonday == 0) {
    daysSinceLastMonday = 0
    daysUntilNextMonday = 7
  }

  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - daysSinceLastMonday);

  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);

  const dateRange = [];
  let currentDate = new Date(lastMonday);

  while (currentDate <= nextMonday) {
    dateRange.push(currentDate.toISOString().slice(0, 10)); // Format as "YYYY-MM-DD"
    currentDate.setDate(currentDate.getDate() + 1);
  }

  dateRange.pop()
  return dateRange
}

const Scheduler = props_ => {
  const { date, setDate, user } = props_
  const calendarInput = useRef(null)
  const [pickerShown, setPickerShown] = useState(false)
  const [dailyView, setDailyView] = useState(true)
  const [tooltip, setTooltip] = useState(null)
  const [currentTooltip, setCurrentTooltip] = useState(null)
  const navigate = useNavigate()
  const currentDate = Date.now()
  const small = false
  console.log(props_.startingHour, props_.endingHour)
  console.log()
  const hours = range(props_.startingHour ?? 1, props_.endingHour ?? 24).map(x => `${x}:00`)

  const props = JSON.parse(JSON.stringify(props_))
  props.schedulerData = typeof props.schedulerData == 'string' ? JSON.parse(props.schedulerData).map(modifyAppointmentsForUI) : props.schedulerData
  console.log("datastts", props.schedulerData)

  const appointmentsGroupedByDay = {}
  for (const app of props.schedulerData) {
    const startDateTime = new Date(app.startDate)
    const startDate = formatDateYYMMDD(startDateTime)
    // console.log("stdt", app.startDate)
    if (!appointmentsGroupedByDay[startDate]) appointmentsGroupedByDay[startDate] = [app]
    else appointmentsGroupedByDay[startDate].push(app)
  }
  for (const k in appointmentsGroupedByDay) {
    const apps = appointmentsGroupedByDay[k]
    if (apps.length == 1) continue
    appointmentsGroupedByDay[k] = [...apps.filter(x => x?.status === undefined), ...apps.filter(x => x?.status == "join" || x?.status == "none")].filter(x => !!x).filter(x => x.actualStatus != "patient-canceled")
  }
  console.log("appointmentsGroupedByDay", appointmentsGroupedByDay)

  console.log("APPOINTMENTS-GROUPED", appointmentsGroupedByDay)
  return <div className="scheduler-container" onClick={() => setTooltip(null)}>
    <div className="scheduler-mainbar">
      <div className="scheduler-topbar">
        <div className="left date-navigator" onClick={() => setDate(addDays(date, dailyView ? -1 : -7))}>
          <img src="https://logowik.com/content/uploads/images/556_arrowright.jpg" />
        </div>
        <div className="right date-navigator" onClick={() => setDate(addDays(date, dailyView ? 1 : 7))}>
          <img src="https://logowik.com/content/uploads/images/556_arrowright.jpg" />
        </div>
        <h1 onClick={() => setPickerShown(!pickerShown)}>
          {date.toDateString()}
        </h1>
        <div className="today-button" onClick={() => setDate(new Date())}>
          <p>Today</p>
        </div>
        <div className="calendar-main">{pickerShown && <DatePicker opened={pickerShown} selected={date} onChange={date => {
          const date2 = new Date(date.format('MM-DD-YYYY'))
          setDate(date2)
          setPickerShown(false)
        }} />}</div>
      </div>
      {!isPhone() && <div className="view-button" onClick={() => {
        const [day1] = daysOfWeek(date)
        setDate(new Date(day1))
        setDailyView(!dailyView)
      }}>{dailyView ? "Daily" : "Weekly"}</div>}

    </div>
    {dailyView ? <div className="scheduler-content-container">
      <div className="scheduler-sidebar">
        {hours.map(hour => <div
          className="sidebar-header"
          style={
            let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? {
              height: isPhone() ? "28rem" : "20rem",
            } : dayAppointemnts?.[0] && dayAppointemnts?.[1] && isPhone() ? { height: "28rem" } : {})
          }
        >
          <h3>{hour}</h3>
        </div>)}
      </div>
      <div className="scheduler-content">
        {hours.map(hour => <div
          className="scheduler-content-section"
          style={{
            flexDirection: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status || isPhone() ? "column" : "row"),
            height: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? (isPhone() ? "28rem" : "20rem") : (dayAppointemnts?.[0] && dayAppointemnts?.[1] && isPhone() ? "28rem" : (isPhone() ? "11rem" : "9rem"))),
            justifyContent: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? "space-evenly" : "space-between"),
          }}
        >
          {let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.map(app => <props_.TooltipContent
            key={JSON.stringify(app)}
            className="scheduler-content-item-card"
            appointmentData={app}
            small={!dailyView}
            tooltip={tooltip}
            appointmentStatusChanging={props_.appointmentStatusChanging}
            setAppointmentStatusChanging={props_.setAppointmentStatusChanging}
            setTooltip={setTooltip}
            schedulerData={props.schedulerData}
            setSchedulerData={props_.setSchedulerData}
            isPopup={false}
            setCurrentTooltip={setCurrentTooltip}
            borderColor={app.status == "join" ? "#9CC4B2" : app.status == "none" ? "rgb(77, 123, 185)" : "#006039"}
            style={{
              height: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? "40%" : undefined),
              marginLeft: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] ? (dayAppointemnts[0].status == dayAppointemnts[1].status && dayAppointemnts[0].status == "join" && !isPhone() ? "auto" : "unset") : (app.status == "join" && !isPhone() ? "auto" : "unset")),
            }} />))}
        </div>)}
      </div>
    </div> : <div className="weekly-scheduler-content-container">
      <div className="weekly-scheduler-content-sidebar">
        <div className="scheduler-sidebar">
          {["", ...hours].map(hour => <div
            className="sidebar-header"
          >
            <h3>{hour}</h3>
          </div>)}
        </div>
      </div>
      <div className="weekly-scheduler-complete-content">
        <div className="weekly-scheduler-days">
          {daysOfWeek(date).map(date => <div className="weekly-scheduler-days-item">
            <h2 style={new Date().toISOString().split("T")[0] == date ? {
              color: "rgba(0, 98, 61, 1)",
              fontWeight: 400,
              fontSize: 24,
            } : {}} onClick={() => {
              setDailyView(true)
              setDate(new Date(date))
            }}>{new Date(date).toString().slice(0, 3)}</h2>
            <p style={new Date().toISOString().split("T")[0] == date ? {
              color: "rgba(0, 98, 61, 1)",
              fontWeight: 400,
              fontSize: 16,
            } : {}} onClick={() => {
              setDailyView(true)
              setDate(new Date(date))
            }}>{new Date(date).getDate()}</p>
          </div>)}
        </div>
        <div className="weekly-scheduler-hours" onClick={() => setCurrentTooltip(null)}>
          {hours.map(hour => <div className="weekly-scheduler-line">
            {daysOfWeek(date).map(day => <div className="weekly-schedule-block">
              <div className="weekly-schedule-appointment-block-container">
                {console.log("dayhourapp", day, hour, getAppointmentByDay(new Date(day), hour, appointmentsGroupedByDay) ?? [])}
                {(getAppointmentByDay(new Date(day), hour, appointmentsGroupedByDay) ?? []).sort((a, b) => a?.appointmentStart > b?.appointmentStart ? 1 : -1).map(appointment => <>
                  {/* <h2>{appointment.patient.name}</h2> */}
                  {<div className="small-appointment-container" style={{ borderColor: appointment.status == "join" ? "#9CC4B2" : appointment.status == "none" ? "rgb(77, 123, 185)" : "#006039" }}>
                    <div className="small-title-container">
                      <img src={clock} />
                      <p>{dayjs(appointment.startDate).format("ddd MMMM D, YYYY")} | {dayjs(appointment.startDate).format("hh:mm A")}</p>
                    </div>
                    <div className="small-border-divider-container" >
                      <div className="small-border-divider"></div>
                    </div>
                    <div className="small-details-container">
                      <img src={appointment?.patient?.image} />
                      <p>{appointment?.patient?.name} <span style={{ marginLeft: '.5vw' }}>Pack {appointment?.totalSessions - appointment?.numberOfSessions}/{appointment?.totalSessions}</span></p>
                    </div>
                    <div className="small-details-button-container">
                      <div
                        className="dashboard-btn-main-container"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flexWrap: 'wrap',
                          width: '20rem'
                        }}
                      >
                        <div className="green-btn small-green-button">
                          <SmallBtn
                            small={!dailyView}
                            onClick={async ev => {

                              if (appointment.status == undefined && (appointment.startDateUTC > currentDate || appointment.endDateUTC < currentDate)) return
                              if (appointment.endDateUTC < currentDate && appointment.status == "join") return

                              ev.stopPropagation()
                              if (appointment.status == "join") {
                                setTooltip(null)
                                setAppointmentStatusChanging(true)
                                const givenAppointmentModified = {
                                  ...appointment,
                                  status: undefined,
                                }
                                // setGivenAppointment(givenAppointmentModified);
                                // modifyAppointments(givenAppointmentModified);
                                await bookAppointmentGAPI(givenAppointmentModified)
                                await authReq(
                                  "PATCH",
                                  `/appointment/${appointment.id}`,
                                  { status: "patient-upcoming" }
                                );
                                setAppointmentStatusChanging(false)
                              } else if (!(appointment.startDateUTC > currentDate || appointment.endDateUTC < currentDate)) window.open(`http://localhost:3000/videoCall?token=${encodeURIComponent(appointment.token)}&free=${appointment.free}&id=${appointment.id}`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                            }}
                            containerStyle={{
                              marginBottom: small ? "0.5rem" : undefined,
                              display:
                                appointment.status == "none" ? "none" : undefined,
                              backgroundColor:
                                appointment.status == "join"
                                  ? "#f8b84e"
                                  : "#006039",
                              ...(appointment.status == undefined && (appointment.startDateUTC - 5 * 60 * 1000 > currentDate || appointment.endDateUTC + 5 * 60 * 1000 < currentDate) ? {
                                opacity: 0.5,
                                cursor: "default",
                              } : {}),
                              ...(appointment.endDateUTC + 5 * 60 * 1000 < currentDate && appointment.status == "join" ? {
                                opacity: 0.5,
                                cursor: "default",
                              } : {})
                            }}
                            fontStyle={{
                              color:
                                appointment.status == "join" ? "#006039" : "#fff",
                            }}
                            text={
                              appointment.status == "join"
                                ? "Accept"
                                : "Join session"
                            }
                          />
                        </div>
                        <SmallBtn
                          small={!dailyView}
                          onClick={async ev => {
                            if (appointment.endDateUTC < currentDate && appointment.status == "join") return
                            if (appointment?.status == "join") {
                              ev.stopPropagation()
                              setAppointmentStatusChanging(true)
                              const givenAppointmentModified = {
                                ...appointment,
                                status: undefined,
                                actualStatus: "patient-canceled",
                              }
                              // setGivenAppointment(appointment);
                              // modifyAppointments(givenAppointmentModified);
                              setTooltip(null)
                              setCurrentTooltip(null)
                              await authReq(
                                "PATCH",
                                `/appointment/${appointment.id}`,
                                { status: "patient-canceled" }
                              );
                              setAppointmentStatusChanging(false)
                            } else {
                              console.log("APPMENT", appointment)
                              navigate(
                                `/dashboard/AppointmentStack/rescheduleAppointment?id=${appointment.id
                                }${appointment.free || appointment.slot?.includes(":45") ? "&free=1" : ""}`
                              );
                            }
                          }}
                          containerStyle={{
                            marginBottom: small ? "0.5rem" : undefined,
                            marginTop: appointment.status == "join" && !isPhone() ? undefined : '0.5rem',
                            display:
                              appointment.status == "none" ? "none" : undefined,
                            border:
                              appointment.status == "join"
                                ? "none"
                                : "0.7px solid #006039",
                            ...(appointment.endDateUTC < currentDate && appointment.status == "join" ? {
                              opacity: 0.5,
                              cursor: "default",
                            } : {}),
                            backgroundColor:
                              appointment.status == "join" ? "#EC4B4A" : "#fff",
                          }}
                          fontStyle={{
                            color:
                              appointment.status == "join" ? "#fff" : "#006039",
                          }}
                          text={
                            appointment.status == "join" ? "Cancel" : "Reschedule"
                          }
                        />
                        {appointment?.status === "join" && <SmallBtn
                          small={!dailyView}
                          onClick={async () => {
                            if (appointment?.status !== "join") { }
                            else
                              navigate(
                                `/dashboard/AppointmentStack/rescheduleAppointment?id=${appointment.id
                                }${appointment.free || appointment.slot?.includes(":45") ? "&free=1" : ""}`
                              );
                          }}
                          containerStyle={{
                            border: "0.7px solid #006039",
                            backgroundColor: "#fff",
                            marginBottom: false ? "0.5rem" : undefined,
                          }}
                          fontStyle={{
                            color: "#006039",
                          }}
                          text={"Reschedule"}
                        />}
                      </div>
                    </div>
                  </div>}
                </>)}
              </div>
            </div>)}
          </div>)}
        </div>
      </div>
    </div>}
  </div>
}

const findCalendar = async () => {
  console.log("Called Find CALENDAR")
  const calendars = await gapi.client.calendar.calendarList.list()
  const foundCalendar = calendars.result.items.find(x => x.summary === 'Kavan Health')
  console.log("CALENDAR ITEMS", foundCalendar, calendars.result.items)
  if (!foundCalendar) return null
  return foundCalendar
}

export const initCalendar = async () => {
  const foundCalendar = await findCalendar()
  if (foundCalendar) return foundCalendar.id
  const newCalendar = {
    summary: 'Kavan Health',
    description: 'Your calendar managed by Kavan Health',
    timeZone,
    backgroundColor: '#006039',

  }
  const calendar = await gapi.client.calendar.calendars.insert({
    resource: newCalendar
  })
  console.log("CREATED CALENDAR", calendar.result)
  return calendar.result.id
}

const getAuth = async () => {
  const currentToken = gapi?.client?.getToken()
  if (currentToken) {
    localStorage.setItem("google_token", currentToken.access_token)
    return currentToken.access_token
  }
  const token = localStorage.getItem("google_token")
  console.log(currentToken, "WHAT TOKEN?", token)
  if (!token) return null
  gapi?.client?.setToken({ access_token: token })
  try {
    await initCalendar()
    console.log("OK???????")
  } catch (e) {
    apiCalendar.handleSignoutClick()
    localStorage.removeItem("google_token")
    console.log('recallllllllll????')
    return getAuth()
  }
  return token
}

export const doGAPIAction = async (f) => {
  console.log(await getAuth())
  if (!(await getAuth())) apiCalendar.handleAuthClick()
  const interval = setInterval(async () => {
    const authStatus = !!(await getAuth())
    if (gapi && authStatus) {
      console.log("real status", await getAuth())
      if (authStatus) {
        clearInterval(interval)
        await f(gapi)
      }
    }
  }, 1000)
}

export const bookAppointmentGAPI = givenAppointment => doGAPIAction(async () => {
  const calendarId = await initCalendar()
  console.log("INSERTING Appointment", givenAppointment)
  const insertedEvent = await gapi.client.calendar.events.insert({
    calendarId,
    resource: {
      summary: givenAppointment.patient?.name ? `Appointment with ${givenAppointment.patient?.name}` : "Appointment",
      start: {
        dateTime: new Date(givenAppointment.startDateUTC ?? givenAppointment.appointmentStart).toISOString(),
        timeZone,
      },
      end: {
        dateTime: new Date(givenAppointment.endDateUTC ?? givenAppointment.appointmentEnd).toISOString(),
        timeZone,
      }
    },
    conferenceDataVersion: 1,
  })
  console.log("Inserted event", insertedEvent.result.id)
  await authReq(
    "PATCH",
    `/appointment/${givenAppointment._id ?? givenAppointment.id}`,
    { eventId: insertedEvent.result.id }
  )
})

let currentTip = null;
export const TooltipContent = ({ appointmentData, isPopup, setCurrentTooltip, setAppointmentStatusChanging, appointmentStatusChanging, schedulerData, setSchedulerData, setTooltip, className, style, small, borderColor }) => {
  console.log("smallsmall", small)
  const navigate = useNavigate()
  const [givenAppointment, setGivenAppointment] = useState(appointmentData);
  const [currentDate, setCurrentDate] = useState(Date.now());
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(Date.now())
    }, 60_000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    currentTip = givenAppointment;
  }, [givenAppointment]);

  const modifyAppointments = givenAppointment => setSchedulerData(schedulerData.map(app => app.id == givenAppointment.id ? givenAppointment : app).filter(app => app.actualStatus != "patient-canceled"))

  return hidden ? (
    <></>
  ) : (
    <div style={{ ...style, ...(isPopup ? { height: "unset" } : {}) }} className={className} onClick={ev => ev.stopPropagation()}>
      <div className="kwn-appointment_content-container" style={small ? { marginBottom: "1rem" } : { borderLeft: `5px solid ${borderColor}` }}>
        <div className="kwn-appointment-content-slider" style={small || isPhone() ? { display: "none" } : {}}></div>
        <div className="kwn-appointment-content" style={small ? {
          width: "22rem",
        } : {}}>
          {console.log("small5ssmall", small)}
          <div className="kwn-appointment-content-time-container">
            <div className="kwn-appointment-content-time-sub-container">
              <img src={clock} />
              {small ? <h4 style={!small ? { marginLeft: "0.5rem" } : {}}>{new Date(givenAppointment.startDate).toLocaleDateString()} | {new Date(givenAppointment.startDate).toLocaleTimeString().split(":").slice(0, 2).join(":")}</h4> : <h4>
                {dayjs(givenAppointment.startDate).format("ddd MMMM D, YYYY")}
                <span>
                  {" | "}
                  {dayjs(givenAppointment.startDate).format("hh:mm A")}
                </span>
              </h4>}
            </div>
            <div className="kwn-appointment-content-three-dot"></div>
          </div>
          <div className="kwn-appointment-content-divider"></div>
          <div className="kwn-appointment-content-btn-main-container">
            <div className="kwn-appointment-content-profile_name">
              <img src={givenAppointment?.patient?.image} />
              <div className="kwn-appointment-content-profile_name-sub-container">
                <h2>{[givenAppointment?.patient?.name, null][0]}</h2>
                {givenAppointment?.pack && <h2 style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{[`Pack ${givenAppointment?.totalSessions - givenAppointment?.numberOfSessions}/${givenAppointment?.totalSessions}`, null][0]}</h2>}
                <h3>
                  {givenAppointment?.patient?.preferences?.speciality ?? ""}
                </h3>
              </div>
            </div>

            <div
              className="dashboard-btn-main-container"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: 'wrap',
                width: '20rem'
              }}
            >
              {console.log("small2small", small)}
              <SmallBtn
                small={small}
                onClick={async ev => {

                  if (givenAppointment.status == undefined && (givenAppointment.startDateUTC - 5 * 60 * 1000 > currentDate || givenAppointment.endDateUTC + 5 * 60 * 1000 < currentDate)) return
                  if (givenAppointment.endDateUTC < currentDate && givenAppointment.status == "join") return

                  ev.stopPropagation()
                  if (givenAppointment.status == "join") {
                    setTooltip(null)
                    setAppointmentStatusChanging(true)
                    const givenAppointmentModified = {
                      ...givenAppointment,
                      status: undefined,
                    }
                    setGivenAppointment(givenAppointmentModified);
                    modifyAppointments(givenAppointmentModified);
                    await bookAppointmentGAPI(givenAppointmentModified)
                    await authReq(
                      "PATCH",
                      `/appointment/${givenAppointment.id}`,
                      { status: "patient-upcoming" }
                    );
                    setAppointmentStatusChanging(false)
                  } else if (!(givenAppointment.startDateUTC - 5 * 60 * 1000 > currentDate || givenAppointment.endDateUTC + 5 * 60 * 1000 < currentDate)) window.open(`https://staging.kavanhealth.com/videoCall?sessionName=${givenAppointment.id}&userName=${givenAppointment?.appointee?.name?.split?.(' ')?.[0]}`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                }}
                containerStyle={{
                  marginBottom: small ? "0.5rem" : undefined,
                  // marginTop: givenAppointment.status == "join" && !isPhone() ? '0.5rem' : undefined,
                  display:
                    givenAppointment.status == "none" ? "none" : undefined,
                  backgroundColor:
                    givenAppointment.status == "join"
                      ? "#f8b84e"
                      : "#006039",
                  ...(givenAppointment.status == undefined && (givenAppointment.startDateUTC - 5 * 60 * 1000 > currentDate || givenAppointment.endDateUTC + 5 * 60 * 1000 < currentDate) ? {
                    opacity: 0.5,
                    cursor: "default",
                  } : {}),
                  ...(givenAppointment.endDateUTC - 5 * 60 * 1000 < currentDate && givenAppointment.status == "join" ? {
                    opacity: 0.5,
                    cursor: "default",
                  } : {})
                }}
                fontStyle={{
                  color:
                    givenAppointment.status == "join" ? "#006039" : "#fff",
                }}
                text={
                  givenAppointment.status == "join"
                    ? "Accept"
                    : "Join session"
                }
              />
              {console.log("smallsmall", small)}
              {givenAppointment?.status === "join" && <SmallBtn
                small={small}
                onClick={async () => {
                  if (givenAppointment?.status !== "join") { }
                  else
                    navigate(
                      `/dashboard/AppointmentStack/rescheduleAppointment?id=${givenAppointment.id
                      }${givenAppointment.free || givenAppointment.slot?.includes(":45") ? "&free=1" : ""}`
                    );
                }}
                containerStyle={{
                  border: "0.7px solid #006039",
                  backgroundColor: "#fff",
                  marginBottom: small ? "0.5rem" : undefined,
                }}
                fontStyle={{
                  color: "#006039",
                }}
                text={"Reschedule"}
              />}
              <div className="green-btn">
                <SmallBtn
                  small={small}
                  onClick={async ev => {
                    if (givenAppointment.endDateUTC < currentDate && givenAppointment.status == "join") return
                    if (givenAppointment?.status == "join") {
                      ev.stopPropagation()
                      setAppointmentStatusChanging(true)
                      const givenAppointmentModified = {
                        ...givenAppointment,
                        status: undefined,
                        actualStatus: "patient-canceled",
                      }
                      setGivenAppointment(givenAppointmentModified);
                      modifyAppointments(givenAppointmentModified);
                      setTooltip(null)
                      setCurrentTooltip(null)
                      await authReq(
                        "PATCH",
                        `/appointment/${givenAppointment.id}`,
                        { status: "patient-canceled" }
                      );
                      setAppointmentStatusChanging(false)
                    } else {
                      console.log("APPMENT", givenAppointment)
                      navigate(
                        `/dashboard/AppointmentStack/rescheduleAppointment?id=${givenAppointment.id
                        }${givenAppointment.free || givenAppointment.slot?.includes(":45") ? "&free=1" : ""}`
                      );
                    }
                  }}
                  containerStyle={{
                    marginTop: small ? undefined : "0.5rem",
                    display:
                      givenAppointment.status == "none" ? "none" : undefined,
                    border:
                      givenAppointment.status == "join"
                        ? "none"
                        : "0.7px solid #006039",
                    ...(givenAppointment.endDateUTC - 5 * 60 * 1000 < currentDate && givenAppointment.status == "join" ? {
                      opacity: 0.5,
                      cursor: "default",
                    } : {}),
                    backgroundColor:
                      givenAppointment.status == "join" ? "#EC4B4A" : "#fff",
                  }}
                  fontStyle={{
                    color:
                      givenAppointment.status == "join" ? "#fff" : "#006039",
                  }}
                  text={
                    givenAppointment.status == "join" ? "Cancel" : "Reschedule"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const modifyAppointmentsForUI = app => {

  if (app.startDateUTC) return app

  const startDate = new Date(app.appointmentStart).toISOString();
  const endDate = new Date(app.appointmentEnd).toISOString();
  console.log("stdt1", startDate)
  return {
    color: "#ff9747",
    id: app._id,
    patient: app.appointer,
    startDateUTC: app.appointmentStart,
    endDateUTC: app.appointmentEnd,
    startDate: startDate,
    endDate: endDate,
    actualStatus: app.status,
    title: `Appointment date`,
    free: app.slot?.includes(":45"),
    token: app.token,
    numberOfSessions: app.numberOfSessions,
    pack: app.pack,
    totalSessions: app.totalSessions,
    status:
      app.status == "patient-pending"
        ? "join"
        : app.status == "patient-completed"
          ? "none"
          : undefined,
  };
}

const socket = socketIO(baseURL);

const Appointment = () => {
  const navigate = useNavigate();
  const { time } = getAllParams();
  const [user, setUser] = useState({});
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [schedulerData, setSchedulerData] = useState([]);
  const [date, setDate] = useState(new Date())

  const setData = (data) =>
    setSchedulerData(
      data.appointments
        .filter((x) => x.status !== "patient-canceled")
        // .filter((x) => x.status !== "patient-completed")
        .map(modifyAppointmentsForUI)
    );

  useEffect(() => {
    // if(schedulerData?.length) setIsLoading(false)
  }, [schedulerData])

  const doRefresh = () => {

    const actionAfterUser = async (data) => {
      console.log("here??", data)
      const user = data.data
      setUser(data.data);
      const stDate = parseInt(new Date(user?.startDateUTC)?.toString?.().split?.(" ")?.[4]?.split?.(":")?.[0]);
      const enDate = parseInt(new Date(user?.endDateUTC).toString?.().split(" ")[4].split(":")[0]);

      setStartDate(stDate);
      setEndDate(enDate);

      console.log(
        "DATESSS",
        stDate,
        enDate,
        new Date(user?.startDateUTC),
        new Date(user?.endDateUTC)
      )

      console.log("Hello hi!")
      socket.emit("user-enter", { userId: user._id })

      // cache.get(user._id).then(appointments => {
      //   console.log("cache-res", appointments)
      //   if (!schedulerData?.length && appointments) {
      //     // setSchedulerData(appointments)
      //     if (appointments?.length && user?.email) setIsLoading(false)
      //   }
      // })

      // const appointmentData = await authReq("GET", `/appointment?currentDate=${Date.now()}&true&noMessages=true`, null)
      const q = query(collection(fireDB, "appointments"), where("appointee._id", "==", user?._id));
      const querySnapshot = await getDocs(q)
      const appointments = querySnapshot.docs.map(doc => doc.data());
      // querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("4242424224", appointments)
      // console.log(doc.id, " => ", doc.data());
      setData({ appointments })

      const unsub = onSnapshot(q, (querySnapshot) => {
        const appointments = querySnapshot.docs.map(doc => doc.data());
        setData({ appointments })
      });

      if (user?.email) setIsLoading(false)
      // })
      // cache.set(user._id, appointmentData.appointments).then(_ => _)

      return () => unsub()
    }
  
    console.log("localStorage.getItem('user')", localStorage.getItem('user'))
    if(localStorage.getItem('user')) actionAfterUser(JSON.parse(localStorage.getItem('user')))
    else authReq("GET", "/user/me").then(async (data) => {
      localStorage.setItem('user', JSON.stringify(data))
      await actionAfterUser(data)
    })
  };

  const getWeekStart = () => {
    const date = dayjs(new Date());
    // Get the start and end dates of the week that contains the date
    return date.startOf("week");
  };

  useEffect(() => {
    doRefresh();

    window.onpopstate = (event) => {
      console.log(
        `location: ${document.location}, state: ${JSON.stringify(event.state)}`
      );
    };


    socket.on("error", err => console.error(err))
    return () => {
      console.log("OK, CALLED", user._id)
      socket.emit("user-leave", { userId: user._id })
      socket.off("unsub-appointments")
      socket.emit("unsub-appointments", { userId: user._id })
    }
  }, []);

  // useEffect(() => {
  //   const f = async () => {
  //     const appointmentData = await authReq("GET", `/appointment?currentDate=${date.getTime()}&noMessages=true`, null)
  //     setData(appointmentData)
  //   }
  //   f()
  // }, [date])

  useEffect(() => {
    socket.on("calendar-modification", async modification => {
      if (!user || !schedulerData?.length) return

      console.log("Modification", modification, schedulerData)
      doGAPIAction(async () => {
        if (modification.actionType == "modify") {
          const calendarId = await initCalendar()
          await gapi.client.calendar.events.update({
            calendarId,
            eventId: modification.eventId,
            resource: {
              summary: modification.appointment.patient?.name ? `Appointment with ${modification.appointment.patient?.name}` : "Appointment",
              start: {
                dateTime: new Date(modification.appointment.appointmentStart - new Date().getTimezoneOffset() * 60_000).toISOString(),
                timeZone: "UTC",
              },
              end: {
                dateTime: new Date(modification.appointment.appointmentEnd - new Date().getTimezoneOffset() * 60_000).toISOString(),
                timeZone: "UTC",
              }
            },
          })
        } else if (modification.actionType == "delete") {
          const calendarId = await initCalendar()
          await gapi.client.calendar.events.delete({
            calendarId,
            eventId: modification.eventId,
          })
        }
      })
      socket.emit("acknowledge", modification)
      setTimeout(() => {
        if (modification.actionType == "modify") setSchedulerData(schedulerData.map(app => app.id == modification.appointment._id ? modifyAppointmentsForUI(modification.appointment) : app))
        else if (modification.actionType == "delete") setSchedulerData(schedulerData.filter(app => app.id != modification.appointment._id))
        else if (modification.actionType == "create") setSchedulerData([...schedulerData, modifyAppointmentsForUI(modification.appointment)])
      }, 3000)
    })
  }, [user, schedulerData])

  return (
    <div id="appointment-container" className="kavan_admin_main_container">
      <div className="kwn-search">
        <SearchBar />
      </div>
      {(isLoading || !user) && <Loader />}
      <h1 className="kwn-heading">appointments</h1>
      {(
        <div className="kwn-appointsments-appointment_view">
          {/* <GoogleCalender signIn={async () => {
            console.log("calendar appointments", schedulerData)
            const calendarId = await clearCalendar()
            for(const appointment of schedulerData) {
              const event = await gapi.client.calendar.events.insert({
                calendarId,
                resource: {
                  summary: appointment.patient?.name ? `Appointment with ${appointment.patient?.name}` : "Appointment",
                  start: {
                    dateTime: new Date(appointment.startDate).toISOString(),
                    timeZone,
                  },
                  end: {
                    dateTime: new Date(appointment.endDate).toISOString(),
                    timeZone,
                  }
                },
                conferenceDataVersion: 1
              })
              console.log("CALENDAR-EVENT", event)
            }
          }} signOut={() => console.log("Signed Out")}/> */}
          {/* {appointmentArray
            .filter((item) => item.type === selectedType.title)
            .map((item) => {
              return item.type === "Upcoming" ? ( */}
          {console.log(startDate, endDate)}
          <Scheduler
            date={date}
            setDate={setDate}
            user={user}
            schedulerData={schedulerData}
            appointmentStatusChanging={appointmentStatusChanging}
            setAppointmentStatusChanging={setAppointmentStatusChanging}
            setSchedulerData={setSchedulerData}
            TooltipContent={TooltipContent}
            startingHour={Math.max(startDate - 1 ?? 1, 1)}
            endingHour={Math.min(endDate + 1 ?? 24, 24)}
          />
          {/* ) : (
                <AppointmentView type={item.type} />
              );
            })} */}
        </div>
      )}
    </div>
  );
};

export default Appointment;
