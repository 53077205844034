import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Viewers from "../pages/viewers";
import * as Admin from "../pages/admin";
import PrivateRoute from "./privateRoute/PrivateRoute";
import PrivateRoute1 from "./private-route";
import { PrivateComponent } from "./private-route";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Viewers.HomePage />} />
      <Route path="/about" element={<Viewers.AboutUs />} />
      <Route path="/blog" element={<Viewers.Blog />} />
      <Route path="/patientForm" element={<Viewers.PatientForm />} />
      <Route path="/blog/:category/:title" element={<Viewers.ReadBlog />} />
      <Route path="/contactUs" element={<Viewers.ContactUs />} />
      <Route path="/page" element={<Viewers.PolicyPage />} />
      <Route path="/signUp" element={<Viewers.SignUp />} />
      <Route path="/signIn" element={<Viewers.SignIn />} />
      <Route path="/fillOutForm" element={<Viewers.FillOutForm />} />
      <Route path="/createProfile" element={<Viewers.CreateProfile />} />
      <Route
        path="/registrationPending"
        element={<Viewers.RegistrationPending />}
      />
      <Route
        path="/registrationSuccessfull"
        element={<Viewers.RegistrationSuccessfull />}
      />
      <Route path="/forgotPassword" element={<Viewers.ForgotPassword />} />
      <Route path="/confirmPassword" element={<Viewers.ConfirmPassword />} />
      <Route path="/verifyEmail" element={<Viewers.VerifyEmail />} />
      <Route path="/videoCall" element={<Viewers.VideoCall />} />
      <Route
        path="/verifyEmailForgetPassword"
        element={<Viewers.VerifyEmailForgetPassword />}
      />
      <Route path="/choosePofile" element={<Viewers.ChooseProfile />} />
      <Route
        path="/verifyPhoneNumber"
        element={<Viewers.VerifyPhoneNumber />}
      />
      <Route path="/phoneNumber" element={<Viewers.PhoneNumber />} />
      <Route path="/addExperience" element={ <PrivateRoute1> <Viewers.AddExperience /> </PrivateRoute1> } />
      <Route path="/specialist" element={ <PrivateRoute1> <Viewers.Specialist /> </PrivateRoute1> } />
      <Route path="/aboutYourself" element={ <PrivateRoute1> <Viewers.AboutYourself /> </PrivateRoute1> } />
      <Route path="/addQualification" element={ <PrivateRoute1> <Viewers.AddQualification /> </PrivateRoute1> } />
      <Route path="/addDegree" element={ <PrivateRoute1> <Viewers.AddDegree /> </PrivateRoute1> } />
      <Route path="/addSpecialist" element={ <PrivateRoute1> <Viewers.AddSpecialist /> </PrivateRoute1> } />
      <Route path="/addLanguage" element={ <PrivateRoute1> <Viewers.AddLanguage /> </PrivateRoute1> } />
      <Route path="/availability" element={ <PrivateRoute1> <Viewers.Availability /> </PrivateRoute1> } />
      <Route path="/psychologist" element={ <Viewers.Psychologist /> } />
      <Route path="/coach" element={<Viewers.Coach />} />
      <Route path="/chatbotStart" element={<Viewers.ChatbotStart />} />
      <Route path="/chatbot" element={<Viewers.Chatbot />} />
      <Route path="/chatbotEnd" element={<Viewers.ChatbotEnd />} />
      <Route path="/webLogin" element={<Viewers.WebLogin />} />
      <Route path="/otpVerification" element={<Viewers.OtpVerification />} />

      <Route
        path="/addExperienceDegree"
        element={<Viewers.AddExperienceDegree />}
      />

      <Route
        path="dashboard/*"
        element={
          <PrivateRoute1>
            <Admin.Layout />
          </PrivateRoute1>
        }
      />
    </Routes>
  );
};

export default Navigation;
