import React, { useState, useEffect } from "react";
import { user, users } from "../../../assets";
import { SearchBar, Loader } from "../../../components";
import { authReq } from "../../../requests";
import { jsPDF } from "jspdf";
import "./pacients.css";
import { fireDB } from "../../../firebase";
import { collection, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";

const processDate = d => {
  const time = new Date(d).getTime() - new Date().getTimezoneOffset() * 60_000
  return new Date(time).toISOString()
}

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

const sdbm = (str) => {
  let arr = str.split("");
  return arr.reduce(
    (hashCode, currentVal) =>
    (hashCode =
      currentVal.charCodeAt(0) +
      (hashCode << 6) +
      (hashCode << 16) -
      hashCode),
    0
  );
};

const characters = "abcdefghijklmnopqrstuvwxyz";

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const Pacients = () => {
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const patientSet = new Set();

  useEffect(() => {
    if(localStorage.getItem('user')) setUser(JSON.parse(localStorage.getItem('user')))
    else authReq("GET", "/user/me").then((data) => {
      localStorage.setItem('user', JSON.stringify(data))
      setUser(data)
    })
  }, [])

  useEffect(() => {
    console.log("next stppppp", user?.data?._id)
    if(!user?.data?._id) return;

    const setPatients = (data) => {
      console.log("doooootooooo", data)
      const patientDict = {}
      for (const appointment of data.appointments) {
        if (patientDict[appointment?.appointer?._id]) patientDict[appointment?.appointer?._id].push(appointment)
        else patientDict[appointment?.appointer?._id] = [appointment]
      }
      for (const k in patientDict) {
        const v = patientDict[k]
        patientDict[k] = v.sort((a, b) => a.appointmentStart > b.appointmentStart ? 1 : -1)
      }
      console.log("Patients Object", patientDict)
      setPatientData(
        data?.appointments
          ?.filter(x => x?.appointer?._id)
          .map((app) => {
            let seed = sdbm(app.appointer._id);
            const arr = new Array(8)
              .fill(0)
              .map((_) => Math.round(random(Math.abs(seed++)) * 10));
            console.log(patientDict[app.appointer._id][0], patientDict[app.appointer._id][1])
            return {
              id: app._id,
              userInfo: app.appointer?.userInfo,
              name: app.appointer.name,
              lastVisit: `${new Date(patientDict[app.appointer._id]?.[0]?.appointmentStart).toISOString()}`,
              nextVisit: `${patientDict[app.appointer._id][1] ? new Date(patientDict[app.appointer._id][1].appointmentStart).toISOString() : ''}`,
              idNumber: `${arr.join("")}${characters
                .charAt(
                  Math.floor(
                    random(sdbm(app.appointer._id)) * characters.length
                  )
                )
                .toUpperCase()}`,
            };
          })
          .filter((x) => {
            console.log(patientSet, x?.idNumber);
            if (patientSet.has(x?.idNumber)) return false;
            patientSet.add(x?.idNumber);
            return true;
          }))
    }

    const f = async () => {
      console.log("___ aaaa")
      const q = query(collection(fireDB, "appointments"), where("appointee._id", "==", user?.data?._id));
      console.log("___ bbbb")
      const querySnapshot = await getDocs(q)
      const appointments = querySnapshot.docs.map(doc => doc.data());
      console.log("___ ccc", appointments)
      // querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      // setPatients({ appointments })
      console.log("___ ddd", appointments)
      const unsub = onSnapshot(q, (querySnapshot) => {
        const appointments = querySnapshot.docs.map(doc => doc.data());
        setPatients({ appointments })
      });


      setLoading(false)
      return unsub
    }

    const unsub = f()
    // return () => unsub()s
  }, [user]);

  return (
    <div className="kavan_admin_main_container">
      {loading && <Loader />}
      <div className="kwn-search">
        <SearchBar />
      </div>
      <div className="kwn-pacients-count-container">
        <h1 className="kwn-heading">pacientes</h1>
        <div className="kwn-heading-pacients-count-container">
          <div className="kwn-dashboard-appointments_and_patients_view">
            <div className="kwn-dashboard-round_calender_view">
              <img src={users} />
            </div>
            <div>
              <h2>{`${patientData.length}`}</h2>
              <h3>{"pacientes"}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="kwn-pacients-table-view">
        <table>
          <thead>
            <tr>
              <th scope="col">NOMBRE Y APELLIDO</th>
              <th scope="col">LAST VISIT</th>
              <th scope="col">NEXT VISIT</th>
              <th scope="col">INFORMATION</th>
              <th scope="col">ID number</th>
            </tr>
          </thead>
          <tbody>
            {patientData.map((item) => {
              console.log(item.nextVisit, item.lastVisit)
              return (
                <tr>
                  <td data-label={"NOMBRE Y APELLIDO"}>{item.name}</td>
                  <td data-label={"Last Visit"}>{processDate(item.lastVisit).split('T')[0]} {processDate(item.lastVisit).split('T')[1].slice(0, 5)}</td>
                  {item.nextVisit == "" ? (
                    <td data-label={"Next Visit"}>
                      <div className="kawan-pacient-table-create-visit">
                        {/* <p>Create next visit</p> */}
                      </div>
                    </td>
                  ) : (
                    <td data-label={"Next Visit"}>{processDate(item.nextVisit).split('T')[0]} {processDate(item.nextVisit).split('T')[1].slice(0, 5)}</td>
                  )}
                  <td data-label={"INFORMATION"}>{item.userInfo ? <div style={{ color: 'blue', textDecoration: "underline", cursorL: "pointer" }} onClick={() => {
                    if (!item.userInfo) return
                    const doc = new jsPDF()

                    let i = 1
                    let y = 0
                    const pageHeight = doc.internal.pageSize.height
                    const multiple = 20
                    for (const k of ["name", ...Object.keys(item.userInfo).filter(x => x != "name")]) {
                      console.log("WHATS K??", k)

                      doc.setFontSize(16);
                      doc.text(`${camelToSnakeCase(k).split("_").join(" ")}: ${item.userInfo[k]}`, 20, i * multiple);
                      i += 1
                      y += i * multiple
                    }
                    doc.save(`${item.idNumber}.pdf`)
                  }}>
                    Download
                  </div> : 'Not Provided'}</td>
                  <td data-label={"Next Visit"}>{item.idNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pacients;
